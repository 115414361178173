/* eslint-disable react-hooks/exhaustive-deps */
import { Interface } from '@ethersproject/abi'
import { TransactionRequest } from '@ethersproject/abstract-provider'
import { BigNumber } from '@ethersproject/bignumber'
import { Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import BRIDGE_ABI from 'uniswap/src/abis/canxium-bridge.json'
import { SmallButtonPrimary } from 'components/Button'
import { UNIVERSE_CHAIN_INFO } from 'uniswap/src/constants/chains'
import { RPC_PROVIDERS } from 'constants/providers'
import { useSwitchChain } from 'hooks/useSwitchChain'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import useInterval from 'lib/hooks/useInterval'
import ms from 'ms'
import { useEffect, useState } from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { useSearchParams } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useTransaction } from 'state/transactions/hooks'
import { TransactionType, TransferTransactionInfo } from 'state/transactions/types'
import styled from 'styled-components'
import { ExternalLink } from 'theme/components'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import PopupModal from './ErrorPopup'
import { FormTitle } from './FormTitle'
import { FormWrapper } from './FormWrapper'
import { BlueLoader } from './Loader'
import { TransactionStatus } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { UniverseChainId } from 'uniswap/src/types/chains'
import { TransactionReceipt } from "@ethersproject/abstract-provider"

const Initialize = styled.div`
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 10px 10px 0 0;
  margin-top: 40px;
  border: 1px solid #7b7979;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const MiddleBlock = styled.div`
  font-weight: 600;
  letter-spacing: 1px;
  border-bottom: 1px solid #7b7979;
  border-left: 1px solid #7b7979;
  border-right: 1px solid #7b7979;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const TransferCompleted = styled.div`
  font-weight: 600;
  letter-spacing: 1px;
  border-bottom: 1px solid #7b7979;
  border-left: 1px solid #7b7979;
  border-right: 1px solid #7b7979;
  border-radius: 0 0 10px 10px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const Check = styled.div`
  margin-right: 20px;
  background: #0866ff;
  width: 25px;
  height: 25px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
`

const CheckGrey = styled.div`
  margin-right: 20px;
  background: #6c7284;
  width: 25px;
  height: 25px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
`

const iface = new Interface(BRIDGE_ABI)

export default function TransferProgress({
  network,
  transactionHash,
  token,
}: {
  network: { network1: UniverseChainId; network2: UniverseChainId }
  transactionHash?: string
  token: Currency
}) {
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline()
  const [searchParams] = useSearchParams()
  const { account, provider } = useWeb3React()
  const switchChain = useSwitchChain()

  const chainInfo = UNIVERSE_CHAIN_INFO[network.network1]
  const net2ChainInfo = UNIVERSE_CHAIN_INFO[network.network2]
  const blockNumber = useBlockNumber() || 0
  const net2Provider = RPC_PROVIDERS[network.network2]
  const [net2BlockNumber, setNet2BlockNumber] = useState(0)
  const [init, setInit] = useState({ process: true, done: false })
  const [confirm, setConfirm] = useState({ process: false, done: false })
  const [release, setRelease] = useState({ process: false, done: false })
  const [claim, setClaim] = useState({ process: false, done: false })
  const [claimStatus, setClaimStatus] = useState({ signing: false, rejected: false })
  const [complete, setComplete] = useState({ process: false, done: false })
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [releasedAt, setReleasedAt] = useState(0)
  const [claimedAt, setClaimedAt] = useState(0)
  const [claimTx, setClaimTx] = useState('')
  const [receipt, setReceipt] = useState<TransactionReceipt>()
  const closePopup = (boolean: boolean) => {
    setShowPopupModal(boolean)
  }

  const tx = searchParams.get('tx')
  const txHash = tx != null ? tx : transactionHash
  const transaction = useTransaction(txHash)
  useEffect(() => {
    if (init.done) return
    if (transaction?.status == TransactionStatus.Confirmed) {
      setInit({ process: true, done: true })
      setConfirm({ process: true, done: false })
    }
  }, [transaction])

  useEffect(() => {
    if (!provider || transaction?.status == TransactionStatus.Pending || !blockNumber || !chainInfo?.confirmation || confirm.done || !txHash) {
      return
    }

    provider.getTransactionReceipt(txHash).then((receipt) => {
      if (receipt && blockNumber - receipt.blockNumber >= (chainInfo?.confirmation || blockNumber)) {
        setConfirm({ process: true, done: true })
        setRelease({ process: true, done: false })
      }

      setReceipt(receipt)
    })
  }, [blockNumber, transaction])

  useEffect(() => {
    if (!net2ChainInfo?.confirmation) return
    if (release.process && !release.done) {
      const data = iface.encodeFunctionData('releasedAt', [txHash])
      const request: TransactionRequest = {
        to: net2ChainInfo ? net2ChainInfo.bridgeContract : '',
        data,
      }
      net2Provider.call(request, 'latest').then((result) => {
        setReleasedAt(BigNumber.from(result).toNumber())
      })

      return
    }

    if (claim.process && !claim.done) {
      const data = iface.encodeFunctionData('claimedAt', [txHash])
      const request: TransactionRequest = {
        to: net2ChainInfo ? net2ChainInfo.bridgeContract : '',
        data,
      }
      net2Provider.call(request, 'latest').then((result) => {
        setClaimedAt(BigNumber.from(result).toNumber())
      })

      return
    }
  }, [net2BlockNumber])

  useEffect(() => {
    if (!release.process || !net2ChainInfo?.confirmation || release.done || releasedAt == 0) return
    if (net2BlockNumber - releasedAt > net2ChainInfo?.confirmation) {
      switchChain(network.network2).then(() => {
        setRelease({ process: true, done: true })
        setClaim({ process: true, done: false })
      })
    }
  }, [net2BlockNumber, releasedAt])

  useEffect(() => {
    if (!claim.process || claim.done || claimedAt == 0) return
    if (net2BlockNumber - claimedAt > 2) {
      setComplete({ process: true, done: true })
      setClaim({ process: true, done: true })
    }
  }, [net2BlockNumber, claimedAt])

  useEffect(() => {
    if (!claim.process || claim.done || claimStatus.signing || !provider) return

    setClaimStatus({ signing: true, rejected: false })
    const data = iface.encodeFunctionData('claim', [txHash])
    const tx = {
      from: account,
      to: net2ChainInfo.bridgeContract,
      data,
    }

    const info: TransferTransactionInfo = {
      type: TransactionType.TRANSFER,
      currency: '',
      amount: '',
    }

    provider
      .estimateGas(tx)
      .then((estimate) => {
        const gasLimit = calculateGasMargin(estimate)
        provider
          .getSigner()
          .sendTransaction({ ...tx, gasLimit })
          .then((response) => {
            if (tx.data !== response.data) {
              if (!response.data || response.data.length === 0 || response.data === '0x') {
                throw new Error('failed to transfer token')
              }
            }

            setClaimTx(response.hash)
            addTransaction(response, info, deadline?.toNumber())
          })
          .catch((e) => {
            console.log(`Failed to send transaction ${e.message}`)
            setClaimStatus({ signing: false, rejected: true })
          })
      })
      .catch((e) => {
        console.log(`Failed to estimate gas ${e.message}`)
        setClaimStatus({ signing: false, rejected: true })
      })
  }, [claim])

  useInterval(() => {
    net2Provider.getBlockNumber().then((number) => {
      setNet2BlockNumber(number)
    })
  }, ms(`2s`))

  return (
    <FormWrapper>
      <FormTitle>Transfer In Progress</FormTitle>
      <Initialize>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {init.process && !init.done && <Check>1</Check>}
          {init.process && init.done && (
            <Check>
              <AiOutlineCheck />
            </Check>
          )}
          <p>Deposit your {token.name}</p>
        </div>
        {init.process && !init.done && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BlueLoader />
            <span style={{ fontSize: '14px', color: '#0866ff', marginLeft: '10px' }}>Loading</span>
          </div>
        )}
      </Initialize>
      <MiddleBlock>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!confirm.process && !confirm.done && <CheckGrey>2</CheckGrey>}
          {confirm.process && !confirm.done && <Check>2</Check>}
          {confirm.process && confirm.done && (
            <Check>
              <AiOutlineCheck />
            </Check>
          )}
          <div>
            <p style={{ marginBottom: confirm.process && confirm.done ? '4px' : '16px' }}>Block Confirmation</p>
            {confirm.process && confirm.done && (
              <p style={{ fontWeight: 'normal', fontSize: '14px', marginTop: '4px' }}>
                {blockNumber - (transaction && receipt ? receipt.blockNumber : blockNumber)}{' '}
                blocks confirmations
              </p>
            )}
            {confirm.process && !confirm.done && (
              <p style={{ fontWeight: 'normal', fontSize: '14px', marginTop: '4px' }}>
                Approx{' '}
                {(
                  ((chainInfo?.avgBlockTime || 0) *
                    ((chainInfo?.confirmation || 0) -
                      (blockNumber -
                        (transaction && receipt ? receipt.blockNumber : blockNumber)))) /
                  60
                ).toFixed(2)}{' '}
                minutes
              </p>
            )}
          </div>
        </div>
        {confirm.process && !confirm.done && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BlueLoader />
            <span style={{ fontSize: '14px', color: '#0866ff', marginLeft: '10px' }}>
              {(chainInfo?.confirmation || 0) -
                (blockNumber -
                  (transaction && receipt ? receipt.blockNumber : blockNumber))}{' '}
              blocks remaining
            </span>
          </div>
        )}
        {confirm.process && confirm.done && (
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <ExternalLink href={chainInfo?.explorer.url + 'tx/' + txHash}>
              View on Explorer
            </ExternalLink>
          </div>
        )}
      </MiddleBlock>
      <MiddleBlock>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!release.process && !release.done && <CheckGrey>3</CheckGrey>}
          {release.process && !release.done && <Check>3</Check>}
          {release.process && release.done && (
            <Check>
              <AiOutlineCheck />
            </Check>
          )}
          <p>Transfer to {net2ChainInfo.label}</p>
        </div>
        {release.process && !release.done && releasedAt == 0 && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BlueLoader />
            <span style={{ fontSize: '14px', color: '#0866ff', marginLeft: '10px' }}>Checking</span>
          </div>
        )}
        {release.process && !release.done && releasedAt > 0 && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BlueLoader />
            <span style={{ fontSize: '14px', color: '#0866ff', marginLeft: '10px' }}>
              {(net2ChainInfo?.confirmation || 0) - (net2BlockNumber - releasedAt) < 0
                ? 0
                : (net2ChainInfo?.confirmation || 0) - (net2BlockNumber - releasedAt)}{' '}
              blocks remaining
            </span>
          </div>
        )}
      </MiddleBlock>
      <MiddleBlock>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!claim.process && !claim.done && <CheckGrey>4</CheckGrey>}
          {claim.process && !claim.done && <Check>4</Check>}
          {claim.process && claim.done && (
            <Check>
              <AiOutlineCheck />
            </Check>
          )}
          <p>Claim Tokens</p>
        </div>
        {claim.process && !claim.done && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BlueLoader />
            {claimedAt <= 0 ? (
              <span style={{ fontSize: '14px', color: '#0866ff', marginLeft: '10px', marginRight: '5px' }}>
                <p>Proceed in your wallet</p>
              </span>
            ) : (
              <span style={{ fontSize: '14px', color: '#0866ff', marginLeft: '10px', marginRight: '5px' }}>
                <p>{2 - (blockNumber - (claimedAt || 0))} blocks remaining</p>
              </span>
            )}
            {claimedAt <= 0 && claimStatus.rejected && (
              <SmallButtonPrimary
                type="button"
                padding="5px"
                $borderRadius="2px"
                width="70%"
                onClick={async () => {
                  setClaimStatus({ signing: false, rejected: false })
                  setClaim({ process: true, done: false })
                }}
              >
                Claim
              </SmallButtonPrimary>
            )}
          </div>
        )}
        {claim.process && claim.done && (
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <ExternalLink href={net2ChainInfo?.explorer.url + 'tx/' + claimTx}>
              View on Explorer
            </ExternalLink>
          </div>
        )}
      </MiddleBlock>
      <TransferCompleted>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!complete.process && !complete.done && <CheckGrey>5</CheckGrey>}
          {complete.process && !complete.done && <Check>5</Check>}
          {complete.process && complete.done && (
            <Check>
              <AiOutlineCheck />
            </Check>
          )}
          <p>Transfer Completed</p>
        </div>
        {complete.process && !complete.done && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BlueLoader />
            <span style={{ fontSize: '14px', color: '#0866ff', marginLeft: '10px' }}>Loading</span>
          </div>
        )}
        {complete.process && complete.done && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BsFillCheckCircleFill style={{ fontSize: '16px', color: '#27ae60', marginRight: '5px' }} />
            <span style={{ fontSize: '14px', color: '#27ae60' }}>COMPLETED</span>
          </div>
        )}
      </TransferCompleted>
      {showPopupModal && (
        <PopupModal
          status="success"
          content="Transaction completed"
          showPopup={showPopupModal}
          closePopup={closePopup}
        />
      )}
    </FormWrapper>
  )
}
