import { useAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import Column from 'components/Column'
import Row from 'components/Row'
import { CurrencySearchFilters } from 'components/SearchModal/CurrencySearch'
import { ArrowWrapper, SwapSection } from 'components/swap/styled'
import { useIsSupportedChainId } from 'constants/chains'
import { useAccount } from 'hooks/useAccount'
import useSelectChain from 'hooks/useSelectChain'
import styled, { useTheme } from 'lib/styled-components'
import { LimitContextProvider } from 'state/limit/LimitContext'
import { useSwapActionHandlers, useSwapAndLimitContext } from 'state/swap/hooks'
import { CurrencyState } from 'state/swap/types'
import { ThemedText } from 'theme/components'
import { AlertTriangle } from 'ui/src/components/icons'
import Trace from 'uniswap/src/features/telemetry/Trace'
import { InterfacePageNameLocal } from 'uniswap/src/features/telemetry/constants'
import {
  useFormatter,
} from 'utils/formatNumbers'

import { Interface } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'
import { ChainId, Currency } from '@uniswap/sdk-core'
import { toHex } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import BRIDGE_ABI from 'uniswap/src/abis/canxium-bridge.json'
import ConfirmTransfer from 'components/Bridge/ConfirmTransfer'
import ConnectWalletForm from 'components/Bridge/ConnectWalletForm'
import TransferDetailForm from 'components/Bridge/TransferDetailForm'
import TransferProgress from 'components/Bridge/TransferProgress'
import { UNIVERSE_CHAIN_INFO } from 'uniswap/src/constants/chains'
import { CHAIN_IDS_TO_NAMES, CHAIN_IDS_TO_URL_PARAM, URL_PARAM_TO_CHAIN_ID, SupportedInterfaceChainId } from 'constants/chains'
import { BRIDGE_TOKENS } from 'constants/tokens'
import { PermitTransferSignature } from 'hooks/usePermitTransfer'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType, TransferTransactionInfo } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { UniverseChainId } from 'uniswap/src/types/chains'

const iface = new Interface(BRIDGE_ABI)

const CustomHeightSwapSection = styled(SwapSection)`
  height: unset;
`

const ShortArrowWrapper = styled(ArrowWrapper)`
  margin-top: -22px;
  margin-bottom: -22px;
`

const StyledAlertIcon = styled(AlertTriangle)`
  align-self: flex-start;
  flex-shrink: 0;
  margin-right: 12px;
`

const LimitDisclaimerContainer = styled(Row)`
  background-color: ${({ theme }) => theme.surface2};
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
`

const DisclaimerText = styled(ThemedText.LabelSmall)`
  line-height: 20px;
`

export const LIMIT_FORM_CURRENCY_SEARCH_FILTERS: CurrencySearchFilters = {
  showCommonBases: true,
}

type LimitFormProps = {
  onCurrencyChange?: (selected: CurrencyState) => void
}

function BridgeForm({ onCurrencyChange }: LimitFormProps) {
  const selectChain = useSelectChain()
  const {
    chainId,
    currencyState: { inputCurrency, outputCurrency },
    setCurrencyState,
  } = useSwapAndLimitContext()
  const isSupportedChain = useIsSupportedChainId(chainId)

  const theme = useTheme()
  const { onSwitchTokens } = useSwapActionHandlers()
  const { formatCurrencyAmount } = useFormatter()
  const accountDrawer = useAccountDrawer()

  const [searchParams, setSearchParams] = useSearchParams()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline()
  const { account, provider } = useWeb3React()
  const [txHash, setTxHash] = useState<string>()
  const [process, setProcess] = useState<string>('connect')
  const fromChainName = searchParams.get('fromChain') || CHAIN_IDS_TO_URL_PARAM[ChainId.CANXIUM]
  const toChainName = searchParams.get('toChain') || CHAIN_IDS_TO_URL_PARAM[ChainId.MAINNET]
  const fromChain: UniverseChainId = URL_PARAM_TO_CHAIN_ID[fromChainName]
  const toChain: UniverseChainId = URL_PARAM_TO_CHAIN_ID[toChainName]
  const [network, setNetwork] = useState<{ network1: UniverseChainId; network2: UniverseChainId }>({
    network1: fromChain,
    network2: toChain,
  })

  const [token, setToken] = useState<Currency>(BRIDGE_TOKENS[fromChain][0])
  const [inputAmount, setInputAmount] = useState<number>()
  const selectedNetwork = (selectedNetwork: {
    network1: UniverseChainId
    network2: UniverseChainId
  }) => {
    setNetwork(selectedNetwork)
  }
  const selectedToken = (selectedToken: Currency) => {
    setToken(selectedToken)
  }

  const selectedTokenAmount = (input: number) => {
    setInputAmount(input)
  }

  const setProcessState = (process: string) => {
    setProcess(process)
  }

  const onConfirmed = async (allowance: PermitTransferSignature | undefined) => {
    const chainInfo = UNIVERSE_CHAIN_INFO[network.network1]
    if (!account || !provider || !inputAmount || !chainInfo) return
    const amount = parseUnits(inputAmount.toString(), token.decimals).toString()
    const data = iface.encodeFunctionData('transfer', [
      network.network2,
      token.isToken ? token.address : '0x0000000000000000000000000000000000000000',
      amount,
      account,
      token.isToken ? allowance?.nonce : 0,
      token.isToken ? allowance?.deadline : 0,
      token.isToken ? allowance?.signature : '0x01',
    ])

    const tx = {
      from: account,
      to: chainInfo.bridgeContract,
      data,
      ...(token.isNative ? { value: toHex(amount) } : {}),
    }

    const info: TransferTransactionInfo = {
      type: TransactionType.TRANSFER,
      currency: token.name || '',
      amount: inputAmount.toString(),
    }

    try {
      const gasEstimate: BigNumber = await provider.estimateGas(tx)
      const gasLimit = calculateGasMargin(gasEstimate)
      const response = await provider
        .getSigner()
        .sendTransaction({ ...tx, gasLimit })
        .then((response) => {
          if (tx.data !== response.data) {
            if (!response.data || response.data.length === 0 || response.data === '0x') {
              throw new Error('failed to transfer token')
            }
          }

          return response
        })

      setTxHash(response.hash)
      searchParams.set('tx', response.hash)
      setSearchParams(searchParams)
      addTransaction(response, info, deadline?.toNumber())
      setProcessState('progress')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Column gap="xs">
      {process === 'connect' && (
        <ConnectWalletForm network={network} setProcessState={setProcessState} selectedNetwork={selectedNetwork} />
      )}
      {process === 'detail' && (
        <TransferDetailForm
          setProcessState={setProcessState}
          network={network}
          selectedToken={selectedToken}
          selectedTokenAmount={selectedTokenAmount}
        />
      )}
      {process === 'confirm' && (
        <ConfirmTransfer
          setProcessState={setProcessState}
          onConfirmed={onConfirmed}
          network={network}
          token={token}
          amount={inputAmount || 0}
        />
      )}
      {process === 'progress' && <TransferProgress network={network} transactionHash={txHash} token={token} />}
    </Column>
  )
}

export function BridgeFormWrapper(props: LimitFormProps) {
  return (
    <Trace page={InterfacePageNameLocal.Bridge}>
      <LimitContextProvider>
        <BridgeForm {...props} />
      </LimitContextProvider>
    </Trace>
  )
}
