import styled from 'styled-components'

export const FormWrapper = styled.form`
  max-width: 600px;
  background: ${({ theme }) => theme.surface5};
  border-radius: 15px;
  padding: 15px 15px;
  border: 1px solid ${({ theme }) => theme.surface3};

  @media screen and (max-width: 640px) {
    padding: 30px 20px;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.surface3};
  }
`
