import { Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ButtonLight } from 'components/Button'
import { UNIVERSE_CHAIN_INFO } from 'uniswap/src/constants/chains'
import { UniverseChainId } from 'uniswap/src/types/chains'
import { BRIDGE_TOKENS } from 'constants/tokens'
import { useRef, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { SlArrowLeft } from 'react-icons/sl'
import { Text } from 'rebass'
import styled from 'styled-components'

import ButtonDropdownToken from './ButtonDropDownToken'
import PopupModal from './ErrorPopup'
import { FormTitle } from './FormTitle'
import { FormWrapper } from './FormWrapper'
import { Image } from 'ui/src'

const TransferNetwork = styled.div`
  border: 1px solid #7b7979;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const Network1 = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 640px) {
    margin-bottom: 20px;
  }
`

const Network2 = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 640px) {
    margin-top: 20px;
    flex-direction: row-reverse;
  }
`

const Network2Text = styled.div`
  margin-right: 20px;
  text-align: right;
  @media screen and (max-width: 640px) {
    margin-left: 20px;
    text-align: left;
  }
`

const TransferArrow = styled.div`
  text-align: center;
  @media screen and (max-width: 640px) {
    padding-left: 40px;
  }
`

const GreySmallTitle = styled.p`
  margin: 0;
  padding-bottom: 10px;
  letter-spacing: 1px;
  color: #6c7284;
  font-weight: 600;
`

const NetworkTitle = styled.p`
  margin: 0;
  padding-bottom: 10px;
  letter-spacing: 1px;
  color: #3664ed;
  font-weight: 600;
`

const chooseTokenStyle = {
  background: '#2c2f36',
  borderRadius: '10px',
  padding: '10px',
  marginBottom: '25px',
}

const EnterAmount = styled.div`
  background: '#2c2f36';
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 25px;
`

type SetProcessState = (...args: any[]) => void
type SelectedToken = (...args: any[]) => void
type SelectedTokenAmount = (...args: any[]) => void

export default function TransferDetailForm({
  setProcessState,
  selectedToken,
  selectedTokenAmount,
  network,
}: {
  setProcessState: SetProcessState
  selectedToken: SelectedToken
  selectedTokenAmount: SelectedTokenAmount
  network: { network1: UniverseChainId; network2: UniverseChainId }
}) {
  const { account } = useWeb3React()
  const fromNetwork = UNIVERSE_CHAIN_INFO[network.network1]
  const toNetwork = UNIVERSE_CHAIN_INFO[network.network2]
  const [selectedTokenFromDropDown, setSelectedTokenFromDropDown] = useState<Currency | undefined>(
    BRIDGE_TOKENS[network.network1 || 0][0]
  )
  const tokenAmountRef = useRef<HTMLInputElement>(null)
  const [showPopupModal, setShowPopupModal] = useState(false)

  const backToConnect = () => {
    setProcessState('connect')
  }

  const forwardToConfirm = () => {
    selectedToken(selectedTokenFromDropDown)
    if (!tokenAmountRef.current?.value || tokenAmountRef.current?.value === '') {
      setShowPopupModal(true)
      return
    } else {
      selectedTokenAmount(tokenAmountRef.current?.value)
      setProcessState('confirm')
    }
  }

  const formatAddress = (address: string | undefined) => {
    if (address === undefined) return '...'
    return address.slice(0, 7) + '...' + address.slice(-4)
  }

  const setSelectedToken = (selectedToken: Currency | undefined) => {
    setSelectedTokenFromDropDown(selectedToken)
  }

  const closePopup = (boolean: boolean) => {
    setShowPopupModal(boolean)
  }

  return (
    <FormWrapper>
      {showPopupModal && (
        <PopupModal status="error" content="Enter token amount" showPopup={showPopupModal} closePopup={closePopup} />
      )}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SlArrowLeft style={{ fontSize: '25px', marginRight: '20px', cursor: 'pointer' }} onClick={backToConnect} />
        </div>

        {/* <AiOutlineClose style={{ fontSize: '25px', cursor: 'pointer' }} /> */}
      </div>
      <TransferNetwork>
        <Network1>
          <Image height={50} source={fromNetwork?.logo} width={50} />
          <div style={{ marginLeft: '20px' }}>
            <NetworkTitle>{fromNetwork?.label}</NetworkTitle>
            <p style={{ margin: 0 }}>{formatAddress(account)}</p>
          </div>
        </Network1>
        <TransferArrow>
          <FaArrowRight style={{ fontSize: '25px' }} />
        </TransferArrow>
        <Network2>
          <Network2Text>
            <NetworkTitle>{toNetwork?.label}</NetworkTitle>
            <p style={{ margin: 0 }}>{formatAddress(account)}</p>
          </Network2Text>
          <Image height={50} source={toNetwork?.logo} width={50} />
        </Network2>
      </TransferNetwork>
      <div style={chooseTokenStyle}>
        <GreySmallTitle>Token</GreySmallTitle>
        <ButtonDropdownToken setSelectedToken={setSelectedToken} chainId={network.network1 || 0} />
      </div>
      <EnterAmount>
        <GreySmallTitle>Enter amount</GreySmallTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <input
              ref={tokenAmountRef}
              type="number"
              step={0.1}
              style={{
                outline: 'none',
                border: 'none',
                fontSize: '20px',
                color: '#fff',
                background: '#2c2f36',
                padding: '8px',
                fontWeight: 600,
                letterSpacing: '1px',
                width: '180%',
              }}
            />
          </div>
          {/* <span style={{ fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}>MAX</span> */}
        </div>
      </EnterAmount>
      <ButtonLight type="button" $borderRadius="10px" onClick={forwardToConfirm}>
        <Text fontSize={20}>
          Continue
        </Text>
      </ButtonLight>
    </FormWrapper>
  )
}
