import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [Gallery, AnimatedGallery] = createIcon({
  name: 'Gallery',
  getIcon: (props) => (
    <Svg viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M22.448 7.60798L21.248 16.189C21.091 17.282 20.664 18.058 19.991 18.501C19.758 18.621 19.5031 18.5001 19.5031 18.2571V9.66609C19.5031 7.05709 17.9451 5.49909 15.3361 5.49909C15.3361 5.49909 6.22395 5.49909 5.92395 5.49909C5.62395 5.49909 5.61694 5.22896 5.63794 5.10896C5.79594 4.16296 6.17 3.46295 6.75 3.02595C7.357 2.56895 8.17702 2.41005 9.19202 2.55305L19.7531 4.03303C20.7701 4.17703 21.518 4.55809 21.976 5.16609C22.433 5.77309 22.592 6.59498 22.448 7.60798ZM18.0031 9.66511V18.3331C18.0031 20.1111 17.1141 21.0001 15.3361 21.0001H4.66797C2.88997 21.0001 2.00098 20.1111 2.00098 18.3331V9.66511C2.00098 7.88711 2.88997 6.99812 4.66797 6.99812H15.3361C17.1141 6.99812 18.0031 7.88611 18.0031 9.66511ZM3.5 9.66511V16.8431L4.93298 15.41C5.27298 15.06 5.84299 15.06 6.19299 15.41L7.02295 16.2401C7.19295 16.4101 7.48295 16.4101 7.65295 16.2401L12.043 11.8502C12.383 11.5102 12.953 11.5102 13.303 11.8502L16.5031 15.0582V9.66511C16.5031 8.71611 16.2851 8.49812 15.3361 8.49812H4.66797C3.71797 8.49812 3.5 8.71611 3.5 9.66511ZM6.50195 12.5001C7.05295 12.5001 7.5 12.0521 7.5 11.5001C7.5 10.9481 7.05295 10.5001 6.50195 10.5001H6.49402C5.94302 10.5001 5.5 10.9481 5.5 11.5001C5.5 12.0521 5.95095 12.5001 6.50195 12.5001Z"
        fill={'currentColor' ?? '#9B9B9B'}
      />
    </Svg>
  ),
  defaultFill: '#9B9B9B',
})
