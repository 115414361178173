import styled from 'styled-components'

export const FormTitle = styled.div`
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 2px;
  @media screen and (max-width: 640px) {
    font-weight: 485;
    font-size: 20px;
    letter-spacing: 1px;
  }
`
