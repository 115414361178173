import styled from 'styled-components'

export const BlueLoader = styled.div`
  width: 14px;
  height: 14px;
  border: 2px solid #0866ff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  `
