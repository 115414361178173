import { Trans, useTranslation } from 'react-i18next'
import { Currency } from '@uniswap/sdk-core'
import { ColumnCenter } from 'components/Column'
import Column from 'components/Column'
import Row from 'components/Row'
import { SwapResult } from 'hooks/useSwapCallback'
import { useUnmountingAnimation } from 'hooks/useUnmountingAnimation'
import { ReactNode, useMemo, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ExternalLink } from 'theme/components'
import { ThemedText } from 'theme/components/text'

import { AnimationType, CurrencyLoader, LogoContainer, PaperIcon } from './Logos'

export const PendingModalContainer = styled(ColumnCenter)`
  margin: 48px 0 8px;
`

const HeaderContainer = styled(ColumnCenter)<{ $disabled?: boolean }>`
  ${({ $disabled }) => $disabled && `opacity: 0.5;`}
  padding: 0 32px;
  overflow: visible;
`

const StepCircle = styled.div<{ active: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${({ theme, active }) => (active ? theme.accent1 : theme.neutral3)};
  outline: 3px solid ${({ theme, active }) => (active ? theme.accent2 : theme.deprecated_accentTextLightPrimary)};
  transition: background-color ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`

const slideIn = keyframes`
  from { opacity: 0; transform: translateX(40px) }
  to { opacity: 1; transform: translateX(0px) }
`
const slideInAnimation = css`
  animation: ${slideIn} ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`
const slideOut = keyframes`
  from { opacity: 1; transform: translateX(0px) }
  to { opacity: 0; transform: translateX(-40px) }
`
const slideOutAnimation = css`
  animation: ${slideOut} ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`

const AnimationWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 72px;
  display: flex;
  flex-grow: 1;
`

const StepTitleAnimationContainer = styled(Column)<{ disableEntranceAnimation?: boolean }>`
  position: absolute;
  width: 100%;
  align-items: center;
  transition: display ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
  ${({ disableEntranceAnimation }) =>
    !disableEntranceAnimation &&
    css`
      ${slideInAnimation}
    `}

  &.${AnimationType.EXITING} {
    ${slideOutAnimation}
  }
`

export enum ConfirmModalState {
  REVIEWING,
  REJECTED,
  RESETTING_USDT,
  APPROVING_TOKEN,
  PERMITTING,
  PENDING_CONFIRMATION,
}

// This component is used for all steps after ConfirmModalState.REVIEWING
export type PendingConfirmModalState = Extract<
  ConfirmModalState,
  | ConfirmModalState.APPROVING_TOKEN
  | ConfirmModalState.PERMITTING
  | ConfirmModalState.PENDING_CONFIRMATION
  | ConfirmModalState.RESETTING_USDT
>

interface PendingModalStep {
  title: ReactNode
  subtitle?: ReactNode
  bottomLabel?: ReactNode
  logo?: ReactNode
  button?: ReactNode
}

interface PendingModalContentProps {
  steps: PendingConfirmModalState[]
  currentStep: PendingConfirmModalState
  currency: Currency
  swapResult?: SwapResult
  wrapTxHash?: string
  hideStepIndicators?: boolean
  tokenApprovalPending?: boolean
  revocationPending?: boolean
}

interface ContentArgs {
  approvalCurrency?: Currency
  tokenApprovalPending: boolean
  revocationPending: boolean
}

function useStepContents(args: ContentArgs): Record<PendingConfirmModalState, PendingModalStep> {
  const { approvalCurrency, tokenApprovalPending, revocationPending } = args
  const { t } = useTranslation()

  return useMemo(
    () => ({
      [ConfirmModalState.RESETTING_USDT]: {
        title: t('Reset USDT'),
        subtitle: t('USDT requires resetting approval when spending limits are too low.'),
        bottomLabel: revocationPending ? t('Pending...') : t('Proceed in your wallet'),
      },
      [ConfirmModalState.APPROVING_TOKEN]: {
        title: t(`Enable spending ${approvalCurrency?.symbol ?? 'this token'}`),
        subtitle: (
          <ExternalLink href="https://support.uniswap.org/hc/en-us/articles/8120520483085">
            <Trans>Why is this required?</Trans>
          </ExternalLink>
        ),
        bottomLabel: tokenApprovalPending ? t('Pending...') : t('Proceed in your wallet'),
      },
      [ConfirmModalState.PERMITTING]: {
        title: t(`Allow ${approvalCurrency?.symbol ?? 'this token'} to be used for transferring`),
        subtitle: (
          <ExternalLink href="https://support.uniswap.org/hc/en-us/articles/8120520483085">
            <Trans>Why is this required?</Trans>
          </ExternalLink>
        ),
        bottomLabel: t('Proceed in your wallet'),
      },
      [ConfirmModalState.PENDING_CONFIRMATION]: {
        title: t(`Transfer ${approvalCurrency?.symbol ?? 'this token'}`),
        subtitle: t('Sign and broadcast the transaction to transfer your asset.'),
        bottomLabel: revocationPending ? t('Pending...') : t('Proceed in your wallet'),
      },
    }),
    [approvalCurrency?.symbol, revocationPending, tokenApprovalPending]
  )
}

export function PendingModalContent({
  steps,
  currentStep,
  currency,
  hideStepIndicators,
  tokenApprovalPending = false,
  revocationPending = false,
}: PendingModalContentProps) {
  const stepContents = useStepContents({
    approvalCurrency: currency,
    tokenApprovalPending,
    revocationPending,
  })

  const currentStepContainerRef = useRef<HTMLDivElement>(null)
  useUnmountingAnimation(currentStepContainerRef, () => AnimationType.EXITING)

  if (steps.length === 0) {
    return null
  }

  const transactionPending = revocationPending || tokenApprovalPending

  return (
    <PendingModalContainer gap="lg">
      <LogoContainer>
        {/* Shown during the setup approval step, and fades out afterwards. */}
        {currentStep === ConfirmModalState.APPROVING_TOKEN && <PaperIcon />}
        {/* Shown during the setup approval step as a small badge. */}
        {/* Scales up once we transition from setup approval to permit signature. */}
        {/* Fades out after the permit signature. */}
        {currentStep !== ConfirmModalState.PENDING_CONFIRMATION && (
          <CurrencyLoader currency={currency} asBadge={currentStep === ConfirmModalState.APPROVING_TOKEN} />
        )}
      </LogoContainer>
      <HeaderContainer gap="md" $disabled={transactionPending}>
        <AnimationWrapper>
          {steps.map((step) => {
            // We only render one step at a time, but looping through the array allows us to keep
            // the exiting step in the DOM during its animation.
            return (
              Boolean(step === currentStep) && (
                <StepTitleAnimationContainer
                  disableEntranceAnimation={steps[0] === currentStep}
                  gap="md"
                  key={step}
                  ref={step === currentStep ? currentStepContainerRef : undefined}
                >
                  <ThemedText.SubHeaderLarge textAlign="center" data-testid="pending-modal-content-title">
                    {stepContents[step].title}
                  </ThemedText.SubHeaderLarge>
                  <ThemedText.LabelSmall textAlign="center">{stepContents[step].subtitle}</ThemedText.LabelSmall>
                </StepTitleAnimationContainer>
              )
            )
          })}
        </AnimationWrapper>
        <Row justify="center" marginTop="32px" minHeight="24px">
          <ThemedText.BodySmall color="neutral2">{stepContents[currentStep].bottomLabel}</ThemedText.BodySmall>
        </Row>
      </HeaderContainer>
      {stepContents[currentStep].button && <Row justify="center">{stepContents[currentStep].button}</Row>}
      {!hideStepIndicators && (
        <Row gap="14px" justify="center">
          {steps.map((_, i) => {
            return <StepCircle key={i} active={steps.indexOf(currentStep) === i} />
          })}
        </Row>
      )}
    </PendingModalContainer>
  )
}
