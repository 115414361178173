import { PermitTransferFrom, SignatureTransfer } from '@uniswap/permit2-sdk'
import { Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { permit2Address } from '@uniswap/permit2-sdk'
import ms from 'ms'
import { useCallback } from 'react'
import { toReadableError, UserRejectedRequestError } from 'utils/errors'
import { signTypedData } from 'utils/signing'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

const PERMIT_SIG_EXPIRATION = ms(`30m`)

function toDeadline(expiration: number): number {
  return Math.floor((Date.now() + expiration) / 1000)
}

export interface PermitTransferSignature extends PermitTransferFrom {
  signature: string
}

export function useUpdatePermitTransfer(
  token: Token | undefined,
  spender: string | undefined,
  amount: string | undefined,
  onPermitSignature: (signature: PermitTransferSignature) => void
) {
  const { account, chainId, provider } = useWeb3React()
  return useCallback(async () => {
    try {
      if (!chainId) throw new Error('missing chainId')
      if (!provider) throw new Error('missing provider')
      if (!token) throw new Error('missing token')
      if (!spender) throw new Error('missing spender')
      if (!account) throw new Error('missing account')
      if (!amount) throw new Error('missing amount')

      const nonce = await provider.getTransactionCount(account)
      const permit: PermitTransferFrom = {
        permitted: {
          token: token.address,
          amount,
        },
        nonce,
        spender,
        deadline: toDeadline(PERMIT_SIG_EXPIRATION),
      }

      const permit2AddressForChain = permit2Address(chainId)
      const { domain, types, values } = SignatureTransfer.getPermitData(permit, permit2AddressForChain, chainId)
      const signature = await signTypedData(provider.getSigner(account), domain, types, values)
      onPermitSignature?.({ ...permit, signature })
      return
    } catch (e: unknown) {
      const symbol = token?.symbol ?? 'Token'
      if (didUserReject(e)) {
        throw new UserRejectedRequestError(`${symbol} permit allowance failed: User rejected signature`)
      }
      throw toReadableError(`${symbol} permit allowance failed:`, e)
    }
  }, [account, chainId, onPermitSignature, provider, spender, token, amount])
}
