import { useTranslation } from 'react-i18next'
import { FeatureFlags } from 'uniswap/src/features/gating/flags'
import { useFeatureFlag } from 'uniswap/src/features/gating/hooks'

export interface MenuItem {
  label: string
  href: string
  internal?: boolean
  overflow?: boolean
  closeMenu?: () => void
}

export interface MenuSection {
  title: string
  items: MenuItem[]
  closeMenu?: () => void
}

export const useMenuContent = (): MenuSection[] => {
  const { t } = useTranslation()
  const isLegacyNav = !useFeatureFlag(FeatureFlags.NavRefresh)

  const legacyAppLinks = {
    title: t('common.app'),
    key: 'App',
    items: [
      { label: t('common.pool'), href: '/pool', internal: true, overflow: true },
      { label: t('common.analytics'), href: '/explore', internal: true },
    ],
  }
  const helpLinks = {
    title: t('common.needHelp'),
    key: 'Help',
    items: [
      { label: t('common.contactUs.button'), href: 'https://discord.gg/SbxSPJ8FkD' },
    ],
  }

  return [...(isLegacyNav ? [legacyAppLinks] : []), helpLinks]
}
