import React from 'react'
import Popup from 'reactjs-popup'
import styled from 'styled-components'

const StyledErrorPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background: rgba(0, 0, 0, 0.7);
  }
  // use your custom style for ".popup-content"
  &-content {
    background: #b71d18;
    padding: 0 40px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 2px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    @media screen and (max-width: 640px) {
      font-weight: 600;
      font-size: 22px;
      letter-spacing: 1px;
    }
  }
`

const StyledSuccessPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background: rgba(0, 0, 0, 0.7);
  }
  // use your custom style for ".popup-content"
  &-content {
    background: #27ae60;
    padding: 0 40px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 2px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    @media screen and (max-width: 640px) {
      font-weight: 600;
      font-size: 22px;
      letter-spacing: 1px;
    }
  }
`

type ClosePopup = (...args: any[]) => void

const PopupModal = ({
  status,
  content,
  showPopup,
  closePopup,
}: {
  status: any
  content: any
  showPopup: boolean
  closePopup: ClosePopup
}) => {
  return (
    <>
      {status === 'error' && (
        <StyledErrorPopup open={showPopup} onClose={() => closePopup(false)}>
          <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '30px' }}>ERROR!</p>
          <p style={{ textAlign: 'center', marginTop: '10px' }}>{content}</p>
        </StyledErrorPopup>
      )}
      {status === 'success' && (
        <StyledSuccessPopup open={showPopup} onClose={() => closePopup(false)}>
          <p style={{ textAlign: 'center', marginBottom: 0, fontSize: '30px' }}>SUCCESS!</p>
          <p style={{ textAlign: 'center', marginTop: '10px' }}>{content}</p>
        </StyledSuccessPopup>
      )}
    </>
  )
}

export default PopupModal
